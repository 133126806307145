import axios from 'axios'
import _ from 'lodash'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import {
  accommodationCategoryArray,
  activityCategoryArray,
  attractionsCategoryArray,
  destinationCategoryArray,
  iBrochureLanguagesMap,
  informationServicesCategoryArray,
  infrastructureCategoryArray,
  otherCategoryArray,
  restaurantCategoryArray,
  venuesCategoryArray
} from '../configs/profileConfig'
import { profileTypeMap } from '../configs/profileTypeConfig'
import { AssociationType_DB_Enum, DocumentType_PEnum, DocumentTypes_DB_PEnum, MediaType_PEnum, ProfileCategory_PEnum, ProfileStatus_PEnum, ProfileType_PEnum, useFileUploader_PEnums } from '../configs/pseudoEnums'
import { TranslatableText } from '../utility/TranslatableText'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateUTCShort = (value) => {
  if (value) {
    const date = new Date(value)
    const formatting = { year:'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }
    return new Intl.DateTimeFormat('en-GB', formatting).format(date)
  } else {
    return ''
  }
}

export const formatDateYearShort = (date) => {
  if (date) {
    if (typeof date === 'string') {
      date = new Date(date)
    }
    const day = date.getDate().toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    const month = (date.getMonth() + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    const year = date.getFullYear()
    const yy = year.toString().substr(-2)
    return `${day}.${month}.${yy}`
  }
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}
 
// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  //*** PLEASE NO NOT CHANGE THESE VALUES WITHOUT TESTING ALL THE AREAS THEY AFFECT ***
  colors: {
    ...theme.colors,
    primary25: '#6CB7411a', // for option hover bg-color - USED FOR DROP DOWNS IN SLEEPING ARRANGEMENTS OVERLAY
    primary50: '#6CB74166', //for active colour on select 
    //primary75 not sure where this is used in component but if there are issues this value should be checked
    primary: '#6CB741', // for selected option bg-color - (this is our Wetu $primary colour, see _variables.scss) - USED FOR DROP DOWNS IN SLEEPING ARRANGEMENTS OVERLAY
    neutral10: '#82868b', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const updateOrAddItem = (existingItems, updateItem, comparisonField) => {
  //If item of this type exists in the existingItems array update it, otherwise add it. 
  //Can be used of any object that has a 'comparisonField' property, eg. for contacts, pass 'type'.
  if (!existingItems || existingItems.length === 0) {
    return [updateItem]
  }
  let found = false
  existingItems = existingItems.map((existingItem) => {
    if (existingItem[comparisonField] === updateItem[comparisonField]) {
      found = true
      return updateItem
    }
    return existingItem
  })
  if (!found) {
    existingItems.push(updateItem)
  }
  return existingItems
}

export const sortObjectArray = (array, sortBy, groupBy) => {
  //Sorts an object array by object property name sortBy. groupBy parameter is optional.
  if (groupBy) {
    sortObjectArray(array, groupBy)
  }
  return array?.sort((a, b) => (
    (!groupBy || (groupBy && a[groupBy] === b[groupBy])) ? a[sortBy] > b[sortBy] ? 1 : -1 : 0
  ))
}

export const sortObjectArrayDesc = (array, sortBy, groupBy) => {
  //Sorts an object array (descending) by object property name sortBy. groupBy parameter is optional.
  if (groupBy) {
    sortObjectArrayDesc(array, groupBy)
  }
  return array?.sort((a, b) => (
    (!groupBy || (groupBy && a[groupBy] === b[groupBy])) ? a[sortBy] < b[sortBy] ? 1 : -1 : 0
  ))
}

export const deleteItem = (existingItems, comparisonField, comparisonValue) => {
  const findIndex = existingItems?.findIndex(x => x[comparisonField] === comparisonValue)
  if (findIndex >= 0) {
    existingItems.splice(findIndex, 1)
  }
  return existingItems
}

export const updateObject = (targetObject, updateSourceObject) => {
  //Updates properties of targetObject with properties of updateSourceObject of the same name. eg. updateObject(profile, {name: 'Ellerman Hoos'}) will update the profile's name to Ellerman Hoos if profile has a property called 'name'.
  //Can be used on any object. targetObject is passed and changed by reference, so no return value needed.
  for (const propertyName in updateSourceObject) {
    if (targetObject[propertyName] !== undefined) {
      targetObject[propertyName] = updateSourceObject[propertyName]
    }
  }
}

export const firstLetterUpper = (word) => {
  return word?.substring(0, 1).toUpperCase() + word?.substring(1)
}

export const getWordCount = (text) => {
  return text ? text.trim().split(' ').length : 0
}

const addPlural = (word, pluralSuffix) => {
  if (pluralSuffix === 'ies' || pluralSuffix === 'ves') {   //https://www.teachstarter.com/au/teaching-resource/rules-for-plurals-s-es-ies-ves/
    word = word.slice(0, -1)   //category => categor. But most words are just 's', eg. document => documents
  }
  return word + pluralSuffix
}

export const pluralise = (word, checker, pluralSuffix = 's') => {
  //If plural suffix not supplied assume 's'.
  if (typeof checker === 'number') {
    return checker !== 1 ? addPlural(word, pluralSuffix) : word
  } else if (typeof checker === 'boolean') {
    return checker ? addPlural(word, pluralSuffix) : word
  }
  return 'unknown'
}

export const replaceAll = (text, findString, replaceString) => {
  const array = text.split(findString)
  return array.join(replaceString)
}

export const getWordCountMsg = (inputText, maxWords, errorObject) => {
  //errorObject is a wrapper object so we can return a primitive type (isError) by reference.
  const wordCount = getWordCount(inputText)
  const diff = maxWords - wordCount
  const wordLabel = pluralise('word', Math.abs(diff), 's')

  if (diff > 0) {
    return `${diff} ${wordLabel} remaining`
  } else if (diff < 0) {
    if (errorObject) {
      errorObject.isError = true
    }
    return `${diff * -1} ${wordLabel} too many`
  }
  return `${maxWords} words exactly!`
}

export const logError = (err) => {
  console.error(err)                   //eg. "Error: Request failed with status code 500."
  if (err?.response?.data) {
    console.error(err.response.data)   //ex.Message and StackTrace from API.
  }
}

export const getDefaultLandingRowsArray = () => {
  const rows = []
  const classColours = ['landing-color-green', 'landing-color-yellow', 'landing-color-red', 'landing-color-blue']
  for (let i = 0; i < 50; i++) {
    const row = {id: i, statusColour: classColours[Math.floor(Math.random() * 4)], ratingColour: classColours[Math.floor(Math.random() * 4)] }
    rows.push(row)
  }
  return rows
}

export const getSanitisedFullTextInput = (searchText) => {
  const terms = searchText?.replace(/[\])}[{(-]/g, '').trim() //Remove brackets from search eg. [CPT] and also hyphens. Regex from https://stackoverflow.com/questions/10844194/remove-parenthesis-from-string-in-javascript/10844426
  return `${terms}`
}

export const getQueryStringId = () => {
  const loc = window.location.search
  const questionMarkPos = loc?.indexOf('?')
  if (questionMarkPos >= 0) {
    const equalsPos = loc?.indexOf('=', questionMarkPos + 1)
    if (equalsPos >= 0) {
      return loc?.substring(equalsPos + 1)
    }
  }
  return ''
}

export const cleanDraftToHtml = (html) => {
  //The HTML generated by 'draftToHtml' (3rd party) is unusual. This function does find/replace to create the HTML we want.
  if (html === '<p></p>\n') {
    //This ensures translationItems get deleted when they delete a translation.
    html = ''
  }
  return html?.replace(/<ins>/g, "<u>").replace(/<\/ins>/g, "</u>")
}

export const sortObject = (obj) => {
  return Object.keys(obj).sort().reduce(function (result, key) {
      result[key] = obj[key]
      return result
  }, {})
}

const DegreeToRadian = (angle) => Math.PI * angle / 180.0

// Using similar haversineCalculation to Backend API
export const haversineCalculation = (lat1, lng1, lat2, lng2) => {
  const earthRadius = 3958.75
  const dLat = DegreeToRadian(lat2 - lat1)
  const dLng = DegreeToRadian(lng2 - lng1)

  const a = (Math.sin(dLat / 2)
      * Math.sin(dLat / 2))
      + ((Math.cos(DegreeToRadian(lat1))
      * Math.cos(DegreeToRadian(lat2)))
      * (Math.sin(dLng / 2) * Math.sin(dLng / 2)))

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const dist = earthRadius * c

  const kmConversion = 1.609

  const distance = dist * kmConversion
  return distance
}

export const toTitleCase = (sentence) => {
  //Converts "this is a title" to "This Is A Title"
  return sentence.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    }
  )
}

export const toPascalCase = (sentence) => {
  const titleCaseSentence = toTitleCase(sentence)
  return titleCaseSentence.replace(/\s/g, '')
}

export const firstLettersUpper = (str) => {
  return str.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function(c) { return c.toUpperCase() })
}

export const toSentenceCase = (sentence) => {
  //Converts "sleeping arrangements" to "Sleeping arrangements". Or "this is a sentence. this is another" to "This is a sentence. This is another"
  return firstLettersUpper(sentence)
}

export const getFileExtension = (url) => {
  const fileExtension = url.split(/[#?]/)[0].split('.')?.pop()?.trim()?.toLowerCase()
  return fileExtension ? fileExtension : ''
}

export const getViewableContentType = (fileExtensionLower) => {
  //Return the content type for file types that can be viewed in a new browser tab.
  return fileExtensionLower === 'pdf' ? 'application/pdf'
  : fileExtensionLower === 'jpg' ? 'image/jpeg'
  : fileExtensionLower === 'jpeg' ? 'image/jpeg'
  : fileExtensionLower === 'png' ? 'image/png'
  : fileExtensionLower === 'gif' ? 'image/gif'
  : fileExtensionLower === 'svg' ? 'image/svg+xml'
  : ''
}

export const openLink = async (url) => {
  //PDFs and images should be opened in new browser tab by getting data in bytes and setting content type: //https://stackoverflow.com/questions/61135269/displaying-pdf-file-from-azure-blob-storage-container-in-new-tab
  //By default window.open(url, "_blank") downloads the file.
  const config = {
      responseType: "blob"
  }
  try {
      const fileExtension = getFileExtension(url)
      const contentType = getViewableContentType(fileExtension)
      if (contentType) {
          const response = await axios.get(url, config)
          const fileBytes = new Blob([response.data], { type: contentType })
          url = URL.createObjectURL(fileBytes)
      }
      window.open(url, "_blank")
  } catch (e) {
      console.error('Error opening file', e)
      window.open(url, "_blank")
  }
}

export const getMediaTypeDescriptor = (mediaType, isTitleCase, isPlural = false) => {
  let descriptor = mediaType === MediaType_PEnum.documentation ? 
      'document' :
          mediaType === MediaType_PEnum.video || mediaType === MediaType_PEnum.embedVideo ? 
              'video' :
                  mediaType === MediaType_PEnum.virtualTour ? 
                      'virtual tour' :
                          mediaType === MediaType_PEnum.panorama ?
                              'panorama' : 'image'
  if (isPlural) {
    descriptor += 's'
  }
  return isTitleCase ? toTitleCase(descriptor) : descriptor
}

export const arrayContainsElements = (testArray, elementsArray) => {
  //Returns true if testArray contains all the elements in elementsArray, otherwise false.
  elementsArray = elementsArray.slice().sort()
  testArray = testArray.slice().sort()
  return elementsArray.every(function(value, index) {
    return value === testArray[index]
  })
}

export const getMissingArrayElements = (testArray, elementsArray) => {
  //Returns the elements that are missing from testArray (irrespective of order).
  return elementsArray.filter(x => !testArray.find(y => x === y))
}

const parseWayPointObject = (wayPoint) => {
  let wayPointString = ""
  if (wayPoint) {
    wayPoint.forEach(item => {
      wayPointString = `${wayPointString},${item.lat()},${item.lng()}`
    })
  }
  return wayPointString
}

export const parseDirectionObject = (direction) => {
  const parsedInstructions = []
  let points = ""
  let wayPoints = ""
  let wayPointCount = 0
  direction.forEach(leg => {
    leg.steps.forEach(step => {
      parsedInstructions.push(step.instructions.replace(/<(?:.|\n)*?>/gm, ''))
      points = points + step.path
    })
    wayPoints = parseWayPointObject(leg.via_waypoints).slice(1)
    wayPointCount = leg.via_waypoints.length
  })
  points = points.replaceAll('(', '').replaceAll('),', ';').replaceAll(')', ';').replaceAll(' ', '')
  return {parsedInstructions, points, wayPoints, wayPointCount}
}

export const getLanguageCodes = (iBrochureLanguages, languages) => {
  return iBrochureLanguages.filter(x => languages.find(y => y === x.name))
}

export const removeObjectArrayElements = (objectArray, comparisonField, removeElements) => {
  if (!removeElements) {
    return objectArray
  } 
    
  if (!Array.isArray(removeElements)) {
    removeElements = [removeElements]
  }
  //Removes removeElements from objectArray if there is a match by comparisonField.
  return objectArray?.filter(
    x => !removeElements?.find(
      y => y[comparisonField] === x[comparisonField]
    )
  )
}

export const formatFileSize = (fileSize) => {
  if (fileSize > (1000 * 1000)) {
    return `${(fileSize / (1000 * 1000)).toFixed(1)} MB`
  } else {
    return `${(fileSize / 1000).toFixed(0)} KB`
  }
}

//Returns every nth element of an array. 
//eg. everyNth([1, 2, 3, 4, 5, 6], 2) return [2, 4, 6]
//eg. everyNth([1, 2, 3, 4, 5, 6], 2, 1) return [1, 3, 5]
//Seems to work directly on a JS dictionary (object) across browsers as well, but internet says you should use Object.keys/values(dictionary) instead.
export const everyNth = (arr, nth, offset = 0) => arr?.filter((e, i) => i % nth === nth - 1 - offset)

//Remove array element if found. eg. removeArrayElement([1, 2, 3], 2) returns [1, 3].
export const removeArrayElement = (arr, element) => {
  const findIndex = arr.findIndex(x => x === element)
  if (findIndex >= 0) {
    arr.splice(findIndex, 1)
  }
  return arr
}

//Copies sourceArray[i].propertyName into targetArray[j].propertyName where idFieldName matches
export const copyObjectArrayProperty = (sourceArray, targetArray, propertyName, idFieldName) => {
  sourceArray?.forEach(sourceItem => {
    const targetItem = targetArray.find(x => x[idFieldName] === sourceItem[idFieldName])
    if (targetItem) {
      targetItem[propertyName] = sourceItem[propertyName]
    }
  })
}

export const getNewSequenceNumber = (collection)  => {
  if (collection.length === 0) {
    return 0
  }
  return Math.min.apply(
    Math, collection?.map(
      (item) => {
        return item.sequence
      }
    )
  ) - 1
}

export const isValidUrl = (url) => {
  try {
    return !!new URL(url)
  } catch (e) {
    return false
  }
}

export const convertDraftJsToHtml = (translatableText, langCode) => {
  const draftJsData = TranslatableText.getStringValue(translatableText, langCode)
  let html = ''
  if (draftJsData?.getCurrentContent) {
    html = cleanDraftToHtml(draftToHtml(convertToRaw(draftJsData.getCurrentContent())))
  }

  const existingItem = TranslatableText.get(translatableText, langCode)
  if (html || existingItem?.existsInDb) {   //Don't save a blank translationItem, unless it already exists in DB in which case we should delete it (the API will delete it if stringValue is empty).
    TranslatableText.update(translatableText, langCode, html)
  } else if (!html && !existingItem?.existsInDb) {    //The object contains draftJs data which we don't want to post to the API, so delete array element.
    TranslatableText.deleteTranslatableTextItem(translatableText, langCode)
  }
}

export const convertDraftJsToHtmlOnSave = (translatableText, langCode) => {
  //Based on convertDraftJsToHtml.
  const draftJsData = TranslatableText.getStringValue(translatableText, langCode)
  let html = ''
  if (draftJsData?.getCurrentContent) {
    html = cleanDraftToHtml(draftToHtml(convertToRaw(draftJsData.getCurrentContent())))
  }

  const existingItem = translatableText?.translations?.find(x => x.languageCode === langCode)
  if (existingItem?.existsInDb || existingItem?.isChanged) {   //Don't save a blank translationItem, unless it already exists in DB in which case we should delete it (the API will delete it if stringValue is empty).
    TranslatableText.update(translatableText, langCode, html, false, null)
  } else { //The object contains draftJs data which we don't want to post to the API, so delete array element.
    TranslatableText.deleteTranslatableTextItem(translatableText, langCode)
  }
}

export const getMegaPixels = (mediaItem) => {
  const width = mediaItem?.width
  const height = mediaItem?.height
  if (width > 0 && height > 0) {
      return ((width * height) / 1000000).toFixed(1)
  }
  return 0
}

// Converts a list to a "dictionary" (used with Selector_PEnum to only show the selected controls)
export const getSelectedItemsFromList = (listOfItems) => {
  return listOfItems?.reduce((previous, current) => {
      previous[current] = true
      return previous
  }, {})
}

export const getTypeAndCategoryMapConfig = (profileType, profileCategory) => {
  let type = profileTypeMap[profileType] || profileTypeMap.default      
  
  if (type.categoryOverride) {
    const categoryOverride = type.categoryOverride[profileCategory]
    type = { ...type, ...categoryOverride}
  }

  return type
}

export const getFeatureCategory = (featuresSection) => {
  return [...new Set(featuresSection.map(item => item.category))]
}

export const getFeatureSubCategory = (featuresSection) => {
  return [...new Set(featuresSection.map(item => item.subCategory))]
}

export const updateAllArrayElements = (arr, fieldName, newValue) => {
  if (arr?.length) {
    arr?.map(x => { x[fieldName] = newValue })
  }
  return arr
}

export const getSliderImages = (mediaItems) => {
  const nonDeletedMediaItems = mediaItems?.sort(c => c.sequence).filter(x => !x.isMarkedForDeletion)
  const mediaItemsImages = nonDeletedMediaItems?.filter(x => x.mediaType === MediaType_PEnum.image)

  const sliderImages = nonDeletedMediaItems
      .filter(x => x.mediaType === MediaType_PEnum.sliderImages)
  const sliderMediaImages = mediaItemsImages.slice(1, 4)
  sliderImages.forEach((mediaItem) => {
      sliderMediaImages[mediaItem.sequence] = mediaItem
  })
  return sliderMediaImages
}

export const getProfileCategoryDisplayName = (categoryType) => {
  const categories = [
    ...accommodationCategoryArray, 
    ...activityCategoryArray,
    ...restaurantCategoryArray,
    ...attractionsCategoryArray,
    ...informationServicesCategoryArray,
    ...infrastructureCategoryArray,
    ...destinationCategoryArray,
    ...otherCategoryArray,
    ...venuesCategoryArray
  ]

  const category = categories.find(c => c.value === categoryType)
  return category?.name || ''
}

export const isDomNodeChildOf = (domNode, parentId, compareIdAsSubstring = false) => {
  //Checks if a dom node is a child (or grandchild etc.) of dom node with parentId.
  let parentNode = domNode?.parentNode
  while (parentNode) {
    if (parentNode.id === parentId || (compareIdAsSubstring && parentNode.id?.indexOf(parentId) >= 0)) {
      return true
    }
    parentNode = parentNode.parentNode
  }
  return false
}
export const WEBSITE_REGEX = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/[a-zA-Z0-9\-\/]*)?\/?$/
export const EMAIL_REGEX = /^($|(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/i
//https://regex101.com/r/Q7heva/1
export const NIGHTSBRIDGE_WEBSITE_REGEX = /^((https?:\/\/)?(?:www\.)?)(?:(book\.nightsbridge\.com))\/([\d]*)$/

export const checkEmailValid = (value) => {
  if (!value) {
    return true
  }
    return EMAIL_REGEX.test(value)  
}
export const checkWebsiteValid = (value) => {
  if (!value) {
    return true
  }
    return WEBSITE_REGEX.test(value)  
}
export const firstLetterLower = (word) => {
  return word?.substring(0, 1).toLowerCase() + word?.substring(1)
}

export const getLanguageCodeList = (profileLanguageList) => {
  const languageCodeList = []
  iBrochureLanguagesMap.forEach(iBrochure => {
    profileLanguageList.forEach(language => {
      if (iBrochure.name === language) {
        languageCodeList.push({ name: language, code: iBrochure.code })
      }
    })
  })
  return languageCodeList
}

export const createAssociatedItem = (associatedItem, profileId, type, associatedItems, isRequired = false) => {
  const newAssociatedItem = {}
  newAssociatedItem.associatedProfileId = associatedItem.profileId
  newAssociatedItem.name = associatedItem.name
  newAssociatedItem.latitude = associatedItem.pin.latitude
  newAssociatedItem.longitude = associatedItem.pin.longitude
  newAssociatedItem.type = type
  newAssociatedItem.profileId = profileId
  newAssociatedItem.isPreferred = associatedItems.length === 0
  newAssociatedItem.isPrivate = associatedItem.isPrivate
  newAssociatedItem.sequence = getNewSequenceNumber(associatedItems)
  newAssociatedItem.isRequired = isRequired

  return newAssociatedItem
}

export const replaceAssociatedItem = (associatedItem, associatedReplacing, count = -1) => {
  const newAssociatedItem = {}
  newAssociatedItem.internalId = count
  newAssociatedItem.associatedProfileId = associatedReplacing.profileId
  newAssociatedItem.name = associatedReplacing.name
  newAssociatedItem.latitude = associatedReplacing.pin.latitude
  newAssociatedItem.longitude = associatedReplacing.pin.longitude
  newAssociatedItem.isPrivate = associatedReplacing.isPrivate

  newAssociatedItem.profileId = associatedItem.profileId
  newAssociatedItem.isPreferred = associatedItem.isPreferred
  newAssociatedItem.sequence = associatedItem.sequence
  newAssociatedItem.type = associatedItem.type
  newAssociatedItem.isRequired = associatedItem.isRequired
  return newAssociatedItem
}

export const getDocumentsEnumUiLabel = (documentsEnum) => {
  switch (documentsEnum) {
      case DocumentTypes_DB_PEnum.covidNotice:
          return 'COVID-19 notice'
      case DocumentTypes_DB_PEnum.factSheet:
          return 'Fact sheets'
      case DocumentTypes_DB_PEnum.foodAndBeverage:
          return 'Food and beverage'
      case DocumentTypes_DB_PEnum.faqs:
          return 'FAQs'
      case DocumentTypes_DB_PEnum.otherGeneral:
          return 'Other / General'
      default:
          return documentsEnum
  }
}

export const getDestinationProfile = (destinations) => {
  const profileIds = []

  destinations
        .filter(x => x.type === AssociationType_DB_Enum.destination)
        .forEach(c => { 
          profileIds.push(c.associatedProfileId)
        })
  return {profileIds}
}

export const toFloat = (inputString, isOnChange = false, numDecimalPlaces = null) => {
  //Strips out non-numeric characters. parseFloat is needed because the regex accepts 1.2.3
  let newVal = inputString.replace(/[^\d.-]/g, '')  //Allows 1. or 1.2.3
  if (!isOnChange) {
    newVal = parseFloat(newVal)   //1.2.3 => 1.2
  } else {
    //If this is onChange then we need to allow '1.'
    newVal = newVal.split('.').splice(0, 2).join('.') //1.2.3 => 1.2
  }

  if (numDecimalPlaces) {
    newVal = newVal.substr(0, newVal.indexOf('.') > -1 ? newVal.indexOf('.') + (numDecimalPlaces + 1) : undefined)
  }
  return newVal
}

export const updateUploadMedia = (filesDone, mediaItems) => {
  filesDone.forEach(uploadedMediaItem => {
      const media = mediaItems.find(x => x.id === uploadedMediaItem.id)
      if (media) {
        media.blobRelativePath = uploadedMediaItem.blobRelativePath
        media.blobUrl = uploadedMediaItem.blobUrl
      }
  })

  return mediaItems
}

export const processDoneUpload = (filesDone, mediaItems) => {
    updateUploadMedia(filesDone, mediaItems)
}

export const processUploadError = (filesErrored, mediaItems) => {
  filesErrored.forEach(erroredItem => {
  const media = mediaItems.find(x => x.id === erroredItem.id)
      if (media) {
        media.isUploadErrored = erroredItem.isUploadErrored
      }
  })
  return mediaItems
}

export const getDmoLinkProfile = (profile) => {
  const dmoLinkProfile = profile.associatedItems?.find(associated => associated.type === AssociationType_DB_Enum.dmoLink)
  return dmoLinkProfile
}

export const getDmoLinkProfileId = (profile) => {
  return getDmoLinkProfile(profile)?.associatedProfileId
}

export const getNotMarkedForDeletion = (items) => items.filter(item => !item.isMarkedForDeletion)

export const concatArraysIfNotNull = (...arrays) => {
  //return a concatenation of arrays that are not null, or else [] if they are all null.
 return [].concat(...arrays.filter(Array.isArray))
}

export const getTrackingMediaId = (mediaItem) => {
  if (mediaItem.documentType && mediaItem.documentType !== DocumentType_PEnum.none) { 
    return `${mediaItem.documentType}_${mediaItem.id}`
  }
  return mediaItem.id
}

export const stringIsNullUndefinedOrEmpty = (str) => {
  return str === undefined || str === null || str.length <= 0
}

export const isWetuOwned = (row) => {
  // Wetu owned doesnt always have a operator assigned, if it does it it will be 1
  return !row.profileAccounts?.length || row.profileAccounts?.some(x => x.operatorId === 1)
}

export const isWetuDestinationAreaOrOther = (isWetuUser, profile) => {
  return ((profile.category === ProfileCategory_PEnum.area 
    || (profile.type === ProfileType_PEnum.destination && profile.category === ProfileCategory_PEnum.other)) 
    && isWetuUser)
}

export const isWetuDestinationCountry  = (isWetuUser, profile) => {
  return (profile.category === ProfileCategory_PEnum.country && isWetuUser)
}

export const isWetuInfrastructure = (isWetuUser, profile) => (profile.type === ProfileType_PEnum.infrastructure && isWetuUser)

export const isWetuDestinationAreaOtherOrInfrastructure = (isWetuUser, profile) => (isWetuDestinationAreaOrOther(isWetuUser, profile) 
  || isWetuInfrastructure(isWetuUser, profile))

export const isWetuDestinationCountryAreaOtherOrInfrastructure = (isWetuUser, profile) => (isWetuDestinationAreaOrOther(isWetuUser, profile) 
  || isWetuInfrastructure(isWetuUser, profile)
  || isWetuDestinationCountry(isWetuUser, profile))

  export const buildImageUrl = (
    relativeUrl,
    imageWidth,
    imageHeight,
    thumbnail = false,
    dontScale = false
  ) => {
    const { innerWidth: width, innerHeight: height } = window

    const isPortrait = imageHeight > imageWidth

    if (dontScale) {
      const dimensions =  !isPortrait ? `c${imageWidth}x${imageHeight}` : `c${imageHeight}x${imageWidth}`
      return `${process.env.REACT_APP_MONOLITH_URL}/ImageHandler/${dimensions}/${relativeUrl}`
    }
  
    const aspectRatio = imageWidth / imageHeight

    if (thumbnail) {
      return `${process.env.REACT_APP_MONOLITH_URL}/ImageHandler/c${isPortrait ? "200x300" : "300x200"}/${relativeUrl}`
    }
  
    const calcWidth = (width * aspectRatio) | 0
    const calcHeight = (height * aspectRatio) | 0
  
    const dimensions = !isPortrait ? `c${calcWidth}x${calcHeight}` : `c${calcHeight}x${calcWidth}`
  
    return `${process.env.REACT_APP_MONOLITH_URL}/ImageHandler/${dimensions}/${relativeUrl}`
  }

  export const getThumbnailImage = (mediaItem) => {
    let thumbnailImage = ''

    if (mediaItem.thumbnailBlobRelativePath) {
      thumbnailImage = mediaItem.thumbnailBlobRelativePath 
      ? buildImageUrl(mediaItem.thumbnailBlobRelativePath, mediaItem.width, mediaItem.height, true)
      : mediaItem.localUrl 
    }

    return thumbnailImage
  }

export const getMediaItemUrl = (mediaItem) => {
  if (mediaItem.mediaType === MediaType_PEnum.video) { 
    return mediaItem.localUrl ? mediaItem.localUrl : mediaItem.blobUrl
  }
  return mediaItem.localUrl ? mediaItem.localUrl : buildImageUrl(mediaItem.blobRelativePath, mediaItem.width, mediaItem.height, false) 
}

export const isProfilePublished = (profileStatus) => profileStatus === ProfileStatus_PEnum.Active

export const getProfileType = (suggestionsFor, profileTypes) => {
  let type
  switch (suggestionsFor) {
      case 'associations':
          type = ProfileType_PEnum.destination
          break
      case 'airports':
          type = ProfileType_PEnum.infrastructure
          break
      default:
          type = Array.isArray(profileTypes) && arr.length > 0 ? profileTypes[0] : profileTypes
  }

  return type
}

export const isPrivateProfilesEnabled = (userData) => {
       // check private profile enabled option
       // maps to AddCustomContent
       if (userData?.AccountOptions) {
        const accountOptions = JSON.parse(userData?.AccountOptions)
        // mapping both true and Accommodation for now, we can update this when we simplify the options
        return accountOptions !== null && (accountOptions.AddCustomContent === "Accommodation" || accountOptions.AddCustomContent === "Enabled")
       }
       return false
  }
